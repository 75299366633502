<template>
  <div class="app-container">
    <h2 class="brand-color mb-20">トレーニング一覧</h2>
    <el-button class="mb-20" size="small" icon="el-icon-plus" type="primary" @click="handleCreate()">追加</el-button>
    <el-table :key="tableKey" v-loading="dataLoading" :data="list" border fit highlight-current-row style="width: 100%">
      <el-table-column type="index" :index="indexTable" label="#" align="center" width="50" />

      <el-table-column label="タイトル" width="200" header-align="center">
        <template #default="{ row }">
          <span class="row-inline">{{ row.name }}</span>
        </template>
      </el-table-column>

      <el-table-column label="本文" min-width="200" header-align="center">
        <template #default="{ row }">
          <span class="row-inline">{{ row.desc }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="230px" class-name="small-padding fixed-width" header-align="center">
        <template #default="scope">
          <el-button
            size="mini"
            type="success"
            @click="handleVideo(scope.row)"
          >動画</el-button>
          <el-button
            size="mini"
            type="primary"
            @click="handleEdit(scope.row)"
          >表示</el-button>
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.row)"
          >削除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      class="text-right"
      :total="total"
      :page="listQuery.page"
      :limit="listQuery.per_page"
      @pagination="getList"
    />
  </div>
</template>

<script>

import { fetchList, deleteTrain } from '@/api/training.js'
import Pagination from '@/components/Pagination'

export default {
  name: 'Training',
  components: {
    Pagination
  },
  data() {
    return {
      tableKey: 0,
      list: null,
      dataLoading: true,
      total: 0,
      listQuery: {
        page: 1,
        per_page: 10,
        importance: undefined,
        sort: '+id'
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: 'Edit',
        create: 'Create'
      },
      dialogPvVisible: false,
      pvData: [],
      rules: {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList(data) {
      this.dataLoading = true
      if (data) {
        this.listQuery.page = data.page
        this.listQuery.per_page = data.limit
      }
      fetchList(this.listQuery).then((response) => {
        this.list = response.data.data
        this.total = response.data.total

        setTimeout(() => {
          this.dataLoading = false
        }, 0.1 * 1000)
      })
    },

    indexTable(index) {
      return (this.listQuery.page - 1) * this.listQuery.per_page + index + 1
    },

    handleEdit(row) {
      this.$router.push(this.$route.path + '/' + row.id + '/edit')
    },

    handleCreate() {
      this.$router.push(this.$route.path + '/create')
    },

    handleVideo(row) {
      this.$router.push(this.$route.path + '/' + row.id + '/video-admin')
    },

    handleDelete(row) {
      this.$confirm('このデータを削除してもよろしいですか？')
        .then(_ => {
          deleteTrain(row.id).then((response) => {
            this.$notify({
              title: 'Success',
              message: 'データは正常に削除されました',
              type: 'success',
              duration: 2000
            })
            this.dataLoading = false
            this.getList()
          })
        })
        .catch(_ => {})
    }
  }
}
</script>
<style lang="scss" scoped>
.row-inline {
  display: inline-block;
width: 100%;
white-space: nowrap;
overflow: hidden !important;
text-overflow: ellipsis;
}
</style>
