import request from '@/utils/request'

export function fetchList(query) {
  return request({
    url: '/trainings',
    method: 'get',
    params: query
  })
}

export function fetchTrain(id) {
  return request({
    url: '/trainings/' + id,
    method: 'get'
  })
}

export function createTrain(data) {
  return request({
    url: '/trainings',
    method: 'post',
    data
  })
}

export function updateTrain(id, data) {
  return request({
    url: '/trainings/' + id,
    method: 'put',
    data
  })
}

export function fetchVideo(id) {
  return request({
    url: '/training/' + id + '/video_admin',
    method: 'get'
  })
}

export function deleteTrain(id) {
  return request({
    url: '/trainings/' + id,
    method: 'delete'
  })
}

export function uploadVideo(data) {
  return request({
    url: '/videos',
    method: 'post',
    data
  })
}

export function updateVideo(id, data) {
  return request({
    url: '/videos/' + id,
    method: 'put',
    data
  })
}
